<template>
    <span>
        {{$t('app.message.bookCreatedAndRented')}}
        <v-btn depressed :to="{name: 'BookDetails', params: {id: value.bookId}}">{{$t('app.edit')}}</v-btn>
    </span>
</template>
<script>
export default {
    name: 'BookRentedAndCreated',
    props: {
        value: {
            type:Object
        }
    }
}
</script>